<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Saved external accounts</h3>
        </div>
      </div>
      <div>
        <template>
          <vs-button flat v-round class="flex primary ml-auto mb-8" @click="addBankAccountPopup">
          <div class="flex items-center">
            <plus-icon size="1.5x" class="button-icon"></plus-icon>Add account</div>
          </vs-button>
        </template>

        <vs-table :data="bankAccounts" class="mt-5 stripes">
          <template slot="thead">
            <vs-th width="30%">Description</vs-th>
            <vs-th>Account name</vs-th>
            <vs-th>Account BSB</vs-th>
            <vs-th>Account number</vs-th>
            <vs-th>Remittance</vs-th>
            <vs-th width="10%"></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="key" v-for="(item, key) in data">
              <vs-td :data="item.description">{{ item.description }}</vs-td>
              <vs-td :data="item.accountName">{{ item.accountName }}</vs-td>
              <vs-td :data="item.bsb">{{ maskBsb(item.bsb) }}</vs-td>
              <vs-td :data="item.accountNumber">{{ item.accountNumber }}</vs-td>
              <vs-td :data="item.remittance">
                <template v-for="(email, index) in item.remittance">
                  <span>{{ email }}</span> <br>
                </template>
              </vs-td>
              <vs-td :data="item">
                <a @click="handleDelete(key)" class="ml-4">Delete</a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-card>

    <vs-popup class="add-bank-popup" :active.sync="addBankAccountPopupActive" @close="closeBankAccountForm" :title="'Add external account'">
      <vs-row>
        <div class="flex mb-8">
          <div class="input-grp">
            <label>Account Name <span class="required-text">*</span></label>
            <vs-input class="w-full" v-validate="'required|min:2|max:32'" data-vv-validate-on="blur" data-vv-as="account name" v-model="account.accountName" name="accountName" />
            <span class="text-danger text-sm" v-show="errors.has('accountName')">{{ errors.first("accountName") }}</span>
          </div>

          <div class="input-grp">
            <label class="w-full text-xm font-normal">Description <span class="required-text">*</span></label>
            <vs-input v-validate="'required'" v-model="account.description" data-vv-validate-on="blur" data-vv-as="description" name="description" />
            <span class="text-danger text-sm block py-3" v-show="errors.has('description')">{{ errors.first("description") }}</span>
          </div>
        </div>

        <div class="flex mb-8">
          <div class="input-grp">
            <label class="w-full text-xm font-normal block">BSB <span class="required-text">*</span></label>
            <the-mask class="vs-input" :mask="['###-###']" v-model="account.bsb" name="bsb" id="bsb" @change.native="handleBsb" @blur.native="handleBsb" />
            <span class="text-danger text-sm block py-3" v-show="errors.has('bsb')">{{ errors.first("bsb") }}</span>
          </div>
          <div class="input-grp">
            <label class="w-full text-xm font-normal block">Account Number <span c:2lass="required-text">*</span></label>
            <vs-input v-validate="'required'" v-model="account.accountNumber" type="number" data-vv-validate-on="blur" data-vv-as="account number" name="accountNumber" />
            <span class="text-danger text-sm block py-3" v-show="errors.has('accountNumber')">{{ errors.first("accountNumber") }}</span>
          </div>
        </div>
        <div class="mb-8">
          <div>
            <label class="w-full text-xm font-normal block">Send remittance email to <span class="required-text">*</span></label>
            <template v-for="(email, index) in account.remittance">
              <div :key="index">
                <div class="flex">
                  <vs-input v-validate="'required|email'" v-model="email.email" data-vv-validate-on="blur" data-vv-as="remittance email" :name="'remittance' + index" />
                  <trash-icon size="1x" class="mt-2" @click="handleRemittanceDelete(index)" v-if="account.remittance.length > 1"></trash-icon>
                </div>
                <span class="text-danger text-sm block py-3" v-show="errors.has('remittance'+index)">{{ errors.first("remittance"+index) }}</span>
              </div>
            </template>
          </div>

          <div class="flex items-center" @click="addRemittance">
            <plus-icon size="1x" class="button-icon mr-1"></plus-icon>Add email</div>
          </vs-button>
        </div>

        <vs-col class="flex justify-end items-center">
          <vs-button class="text-sm" size="large" v-round :disabled="validateBank" color="primary" @click="createExternalAccount">Add account</vs-button>
          <a class="text-sm flex items-center underline mid-blue ml-8" @click="closeBankAccountForm">Cancel</a>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup class="delete-bank-popup" :active.sync="deletePopup" @close="closeDeletePopup" title="External account remove">
      <vs-row>
        <div class="flex mb-8">
          <p>Are you sure you want to delete this saved external account?</p>
        </div>
        <div v-if="displayWarning" class="text-red mb-5">
          <p class="text-red">This account is configured as a default disbursement account for following templates:</p>
          <ul class="ml-10" v-if="requestTemplates.length">
            <li v-for="(template, key) in requestTemplates" :key="key" class="mt-2">{{ `- ${template.templateName} (${template.requestTemplateId})` }}</li>
          </ul>
          <p class="mt-2 text-red">Please update the templates before deleting this account</p>
        </div>
        <vs-col class="flex justify-end items-center">
          <vs-button class="text-sm" size="large" v-round color="primary" @click="deleteAccount">Delete</vs-button>
          <a class="text-sm flex items-center underline mid-blue ml-8" @click="closeDeletePopup">Cancel</a>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PlusIcon, TrashIcon } from "vue-feather-icons";
import { TheMask } from "vue-the-mask";


export default {
  name: "SavedExternalAccounts",

  components: { PlusIcon, TheMask, TrashIcon },

  data() {
    return {
      addBankAccountPopupActive: false,
      account: {
        bsb: "",
        remittance: [{ email: ""}]
      },
      bankAccounts: [],
      deletePopup: false,
      id: null,
      displayWarning: false,
      requestTemplates: []
    };
  },

  methods: {
    ...mapActions("savedExternalAccount", ["fetchSavedExternalAccounts", "removeExternalAccount", "saveExternalAccount"]),

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async getBankAccounts() {
      this.$vs.loading();

      await this.fetchSavedExternalAccounts(this.merchantId).then((response) => {
        if (response.data.data && response.data.data.length) {
          this.bankAccounts = response.data.data;
        } else {
          this.bankAccounts = [];
        }

        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage(ex.response.data.title || "Bank", ex.response.data.message, "danger", "icon-check-circle");

        if (ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      });
    },

    addBankAccountPopup() {
      this.addBankAccountPopupActive = true;
      this.account = { remittance: [{ email: "" } ]};
    },

    closeBankAccountForm() {
      this.addBankAccountPopupActive = false;
      this.errors.clear();
    },

    async createExternalAccount() {
      this.account.merchantId = this.merchantId;
      let payload = { ...this.account };
      payload.remittance = payload.remittance && payload.remittance.length ? payload.remittance.map(item => item.email) : [];
      payload.merchantId = this.merchantId;

      await this.saveExternalAccount(payload).then((res) => {
        this.showMessage("Success", "External account has been created successfully.", "success", "icon-check-circle");
        this.addBankAccountPopupActive = false;
        this.getBankAccounts();
      }).catch((ex) => {
        if (ex.response.status == 422) {
          this.showMessage("Validation Failed", ex.response.data.message, "danger", "icon-times");
        } else {
          this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
        }
      });
    },

    handleBsb() {
      let bsbValid = true;
      let bsbRequired = false;

      if (!this.account.bsb || this.account.bsb.length != 6) {
        bsbValid = false;
        bsbRequired = (this.account.bsb.length == 0);
      }

      if (bsbValid) {
        this.errors.remove("bsb");
      } else if (!this.errors.has("bsb")) {
        const errorMessage = bsbRequired ? "The bsb field is required" : "The bsb field is invalid";
        this.errors.add({
          "field": "bsb",
          "msg": errorMessage
        });
      }
    },

    maskBsb(string) {
      if (!string.includes("-")) {
        let arr = string.replace(" ", "").split("");
        arr.splice(3, 0, "-");
        string = arr.join("");
      }

      return string;
    },

    handleDelete(key) {
      this.displayWarning = false;
      this.requestTemplates = [];
      this.deletePopup = true;
      this.id = this.bankAccounts[key]._id;
    },

    handleRemittanceDelete(key) {
      this.account.remittance.splice(key, 1);
    },

    addRemittance() {
      this.account.remittance.push( { email: ""});
    },

    closeDeletePopup() {
      this.id = null;
      this.deletePopup = false;
    },

    async deleteAccount() {
      await this.removeExternalAccount(this.id).then((res) => {
        this.showMessage("Success", "External account has been deleted successfully.", "success", "icon-check-circle");
        this.deletePopup = false;
        this.id = null;
        this.getBankAccounts();
      }).catch((ex) => {
        if (ex.response.status == 422) {
          this.requestTemplates = ex.response.data.data;
          this.displayWarning = true;
          return;
        }
        this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
      });
    },
    isValidEmail(email) {
      const re =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    validateBank() {
      let validEmail = this.account.remittance.filter(item => this.isValidEmail(item.email)).length;
      validEmail = (validEmail === this.account.remittance.length);

      return !this.account.accountName || !this.account.bsb || !this.account.accountNumber || !this.account.description || this.errors.has("bsb") || !validEmail;
    },

    merchantId() {
      return this.user.userType == "admin" ? this.user._id : this.user.partnerId;
    }
  },

  mounted() {
    this.getBankAccounts();
  },
};
</script>

